// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("../src/pages/About/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-drill-sergeant-index-js": () => import("../src/pages/DrillSergeant/index.js" /* webpackChunkName: "component---src-pages-drill-sergeant-index-js" */),
  "component---src-pages-drill-sergeant-privacy-index-js": () => import("../src/pages/DrillSergeant/privacy/index.js" /* webpackChunkName: "component---src-pages-drill-sergeant-privacy-index-js" */),
  "component---src-pages-drill-sergeant-terms-index-js": () => import("../src/pages/DrillSergeant/terms/index.js" /* webpackChunkName: "component---src-pages-drill-sergeant-terms-index-js" */),
  "component---src-pages-index-copy-js": () => import("../src/pages/index copy.js" /* webpackChunkName: "component---src-pages-index-copy-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

